import * as React from 'react';
import { wineClubPickupLocations } from '../../../settings/app.settings';
import { WineClubDataRef } from '../../wine-club-signup/wine-club-data';
import { IOrderDetailsViewProps } from '../smwe-order-details';

export interface IWineClubDetailsProps {
    orderDetailProps: IOrderDetailsViewProps;
    wineClubData: WineClubDataRef;
}

/**
 * renders wine club details ONCE, and wipes
 */
export class WineClubDetails extends React.Component<IWineClubDetailsProps> {
    constructor(props: IWineClubDetailsProps) {
        super(props);
    }

    public render(): JSX.Element {
        if (this.props.wineClubData.variantPreference === null) {
            return <div />;
        }
        return (
            <div className='ms-order-details__wine-club'>
                {this._shipTypeJSX}
                {this._isGiftJSX}
            </div>
        );
    }

    private get _shipTypeJSX(): JSX.Element {
        const shipType = this.props.wineClubData.deliveryType;

        let display = 'Ship To';
        let pickupLocationName: string | undefined;
        let pickupLocationAddress: string | undefined;
        if (shipType === 'PICKUP') {
            display = 'Pickup';
            const locations = this.props.orderDetailProps.context.app.config.wineClubPickupLocations as wineClubPickupLocations || [];
            const selectedLocation = locations.find(location => location.id === this.props.wineClubData.pickupLocationId);
            if (selectedLocation) {
                pickupLocationName = selectedLocation.name;
                pickupLocationAddress = selectedLocation.address;
            }
        }

        return (
            <React.Fragment>
                <div className='ms-order-details__wine-club-ship-type'>
                    <span className='ms-order-details__wine-club-label'>Delivery Method:</span>
                    <span className='ms-order-details__wine-club-value'>{display}</span>
                </div>
                {
                    shipType === 'PICKUP' && (
                        <div className='ms-order-details__wine-club-ship-location'>
                            <span className='ms-order-details__wine-club-label'>Deliver To:</span>
                            <span className='ms-order-details__wine-club-value ms-order-details__wine-club-ship-location-name'>{pickupLocationName} at</span>
                            <span className='ms-order-details__wine-club-value ms-order-details__wine-club-ship-location-address'>{pickupLocationAddress}</span>
                        </div>
                    )
                }
            </React.Fragment>
        );
    }

    private get _isGiftJSX(): JSX.Element {
        return (
            <div className='ms-order-details__wine-club-details-gift-status'>
                <span className='ms-order-details__wine-club-label'>Gift Membership:</span>
                <span className='ms-order-details__wine-club-value'>
                    {
                        this.props.wineClubData.forMyself ? 'No' : 'Yes'
                    }
                </span>
            </div>
        );
    }

}